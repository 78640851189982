import { Action, Reducer } from "redux";
import { PreOrderApi } from "api";
import { AppThunkAction } from "./";
import { ApiError } from "types";
import {
  SetNotificationAction,
  actionCreators as notificationActions,
} from "./Notification";

const preOrderApi = new PreOrderApi();

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface PreOrderState {
  phoneNumber: string;
}

enum PreOrderActionTypes {
  PHONE_NUMBER_SET = "PHONE_NUMBER_SET",
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

interface PhoneNumberSetAction {
  type: PreOrderActionTypes.PHONE_NUMBER_SET;
  payload: string;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = PhoneNumberSetAction | SetNotificationAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
  setPhoneNumber: (payload: string): PhoneNumberSetAction => ({
    type: PreOrderActionTypes.PHONE_NUMBER_SET,
    payload,
  }),
  preOrderIban:
    (
      phoneNumber: string,
      source: string,
      onSuccess?: () => void,
      onError?: () => void
    ): AppThunkAction<KnownAction> =>
    async (dispatch) => {
      try {
        await preOrderApi.preOrderPost(phoneNumber, source);

        if (onSuccess) {
          onSuccess();
        }
      } catch ({ response }) {
        const { data } = response as ApiError;

        if (onError) {
          onError();
        }

        dispatch(
          notificationActions.setNotification({
            message: data.title,
            severity: "error",
          })
        );
      }
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const defaultState: PreOrderState = {
  phoneNumber: "",
};

export const reducer: Reducer<PreOrderState> = (
  state: PreOrderState = defaultState,
  incomingAction: Action
): PreOrderState => {
  const action = incomingAction as KnownAction;
  switch (action.type) {
    case PreOrderActionTypes.PHONE_NUMBER_SET:
      return { ...state, phoneNumber: action.payload };
    default:
      return state;
  }
};
