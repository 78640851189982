import React, { FC } from "react";
import { withRouter, RouteComponentProps } from "react-router";

import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

import { styled } from "@mui/material/styles";

import { ReactComponent as IbanLogo } from "assets/images/iban_oplata-logo.svg";

import analytics from "services/analytics";
import { AnalyticsEvents } from "shared/constants/analytics";

import { mainStyles } from "styles";

const AppBarMui = styled(AppBar)(({ theme }) => ({
  position: "sticky",
  minHeight: 56,
  padding: "16px 0",
  background: theme.palette.common.white,
  display: "flex",
  boxShadow: "none",
  flexDirection: "row",
}));

interface HeaderProps {
  website?: boolean;
}

type AllProps = HeaderProps & RouteComponentProps;

const IbanHeader: FC<AllProps> = () => {
  const phoneNumber = "+380980965020";
  const email = "info@fopkasa.com";

  const links = {
    viber: `viber://chat?number=${phoneNumber}`,
    telegram: `tg://resolve?phone=${phoneNumber}`,
    email: `mailto:${email}`,
  };

  const apiIntegration = () => {
    analytics().sendEvent(AnalyticsEvents.iban_oplata_api_integration);
  };

  const renderContactLinks = () => {
    return (
      <Box sx={styles.linksContainer}>
        <Box sx={styles.socialContainer}>
          <Link
            href={links.viber}
            sx={{ marginRight: "20px" }}
            underline="none"
            color={(theme) => theme.colors.body}
          >
            Viber
          </Link>
          <Link
            href={links.telegram}
            underline="none"
            color={(theme) => theme.colors.body}
          >
            Telegram
          </Link>
        </Box>
        <Box sx={styles.emailContainer}>
          <Link
            href={links.email}
            underline="none"
            color={(theme) => theme.colors.body}
          >
            info@fopkasa.com
          </Link>
        </Box>
      </Box>
    );
  };

  const renderButtons = () => {
    return (
      <Box sx={styles.buttonContainer}>
        <Button
          sx={mainStyles.primaryButton}
          size="medium"
          variant="contained"
          fullWidth
          onClick={apiIntegration}
          href="/iban-oplata#integration"
        >
          <Typography sx={mainStyles.primaryButtonText}>
            API-інтеграція
          </Typography>
        </Button>
      </Box>
    );
  };

  return (
    <AppBarMui>
      <Container sx={styles.headerContainer}>
        <IbanLogo style={{ height: "40px" }} />
        {renderContactLinks()}
        {renderButtons()}
      </Container>
    </AppBarMui>
  );
};

const styles = {
  headerContainer: {
    alignItems: "center",
    display: "flex",
  },
  linksContainer: {
    marginLeft: "auto",
    display: "block",
  },
  socialContainer: {
    marginRight: "20px",
    display: { xs: "none", sm: "inline" },
  },
  emailContainer: {
    display: { xs: "none", md: "inline" },
    paddingLeft: "20px",
    paddingRight: "20px",
    borderLeft: "solid 1px #CDCDCD",
    borderRight: "solid 1px #CDCDCD",
  },
  buttonContainer: {
    marginLeft: "40px",
  },
};

export default withRouter(IbanHeader);
