import React, { useState } from "react";
import { connect } from "react-redux";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import CircularProgress from "@mui/material/CircularProgress";

import CustomPatternFormat from "components/FormInput/PatternFormat";
import CallModal from "containers/Home/components/CallForm/components/Modal";

import UAFlag from "assets/images/ua_flag-icon.svg";
import IntegrationBg from "assets/images/api_integration-bg.png";
import IntegrationImg from "assets/images/api_integration-img.png";

import { actionCreators } from "store/PreOrder";

import analytics from "services/analytics";
import { AnalyticsEvents } from "shared/constants/analytics";

import { blue600, grey000, red100, red800, mainStyles } from "styles";

interface ReduxProps {
  preOrderIban: (
    phoneNumber: string,
    source: string,
    onSuccess?: () => void,
    onError?: () => void
  ) => void;
}

const APIIntegration: React.FC<ReduxProps> = (props) => {
  const { preOrderIban } = props;

  const [phoneNumber, setPhoneNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const isMd = useMediaQuery("(max-width: 600px)");
  const isLg = useMediaQuery("(max-width: 1150px)");

  const onChangePhone = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.target.value.length === 10 && setError(false);
    setPhoneNumber(event.target.value);
  };

  const onClick = () => {
    if (!phoneNumber || phoneNumber.length < 10) {
      setError(true);
      return;
    }

    setLoading(true);
    const phone = "38" + phoneNumber;

    preOrderIban(
      phone,
      "IBAN API інтеграція",
      () => {
        setIsModalOpen(true);
        setLoading(false);
        analytics().sendEvent(`${AnalyticsEvents.iban_oplata_contact_us}_integration`);
      },
      () => {
        setLoading(false);
      }
    );
  };

  const onCloseModal = () => {
    setIsModalOpen(false);
    setPhoneNumber("");
  };

  return (
    <Box sx={styles.installBlock} id="integration">
      <Container sx={styles.container}>
        <Box sx={[styles.textWrapper, isLg && { maxWidth: "100%" }]}>
          <Typography sx={[mainStyles.ui1, styles.uiText]} mb={2.5}>
            API-Інтеграція
          </Typography>
          <Typography sx={[mainStyles.h2, { color: grey000 }]} mb={2.5}>
            Інтегруйте IBAN Oplata API та оптимізуйте платіжні процеси
          </Typography>
          <Typography sx={[mainStyles.body1, { color: grey000 }]}>
            Автоматизуйте генерацію платіжних посилань за допомогою
            API-інтеграції сервісу з вашими CRM та іншими бізнес-платформами.
          </Typography>
          <Box
            display="flex"
            flexDirection={{ xs: "column", sm: "row" }}
            justifyContent="flex-start"
            mt={4}
          >
            <Box sx={[styles.inputWrapper, error && styles.error]}>
              <Box sx={styles.inputImageWrapper}>
                <img src={UAFlag} />
              </Box>
              <TextField
                variant="standard"
                placeholder="+38 (000) 000-0000"
                InputProps={{
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  inputComponent: CustomPatternFormat as any,
                  disableUnderline: true,
                  inputProps: {
                    format: "+38 (###) ### ####",
                  },
                }}
                onChange={onChangePhone}
                value={phoneNumber}
                sx={styles.input}
              />
              {!isMd && (
                <Button
                  sx={[mainStyles.primaryButton, styles.contactBtn]}
                  onClick={onClick}
                  disabled={loading}
                >
                  {loading ? (
                    <CircularProgress
                      size={24}
                      color="inherit"
                      thickness={10}
                    />
                  ) : (
                    <Typography sx={mainStyles.primaryButtonText}>
                      Замовити інтеграцію
                    </Typography>
                  )}
                </Button>
              )}
            </Box>
            {isMd && (
              <Button
                fullWidth
                sx={[
                  mainStyles.secondaryButton,
                  styles.contactBtn,
                  { marginTop: "12px" },
                ]}
                onClick={onClick}
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress size={24} color="inherit" thickness={10} />
                ) : (
                  <Typography sx={mainStyles.secondaryButtonText}>
                    Замовити інтеграцію
                  </Typography>
                )}
              </Button>
            )}
          </Box>
        </Box>
        <Box sx={[styles.imageWrapper, isLg && { display: "none" }]}>
          <img
            src={IntegrationImg}
            alt="API Integration"
            style={styles.image}
          />
        </Box>
      </Container>
      <CallModal
        isOpen={isModalOpen}
        closeModal={onCloseModal}
        phone={`38${phoneNumber}`}
      />
    </Box>
  );
};

const styles = {
  installBlock: {
    backgroundImage: `url(${IntegrationBg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    paddingTop: { xs: "56px", sm: "96px" },
    paddingBottom: { xs: "56px", sm: "96px" },
    textAlign: "left",
    backgroundColor: blue600,
  },
  container: {
    display: "flex",
    flexDirection: { xs: "column", sm: "row" },
    justifyContent: "space-between",
    alignItems: "center",
  },
  textWrapper: {
    maxWidth: "45%",
    marginRight: { xs: 0, md: "20px" },
  },
  uiText: {
    color: grey000,
  },
  inputWrapper: {
    backgroundColor: grey000,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    borderRadius: "28px",
    border: `2px solid ${grey000}`,
    padding: "2px",
  },
  input: {
    marginLeft: "12px",
    minWidth: "210px",
    "& .MuiInputBase-input": {
      fontFamily: "Inter",
      fontSize: 16,
      fontWeight: "400",
      lineHeight: "24px",
      letterSpacing: "2px",
      minWidth: "210px",
    },
  },
  error: {
    border: `2px solid ${red800}`,
    backgroundColor: red100,
    margin: "0",
  },
  inputImageWrapper: {
    display: "flex",
    alignItems: "center",
    margin: "8px 0 8px 15px",
    paddingRight: "12px",
    borderRight: "1px solid #A6A7AD",
  },
  contactBtn: {
    padding: "12px 20px",
  },

  imageWrapper: {
    minWidth: "45%",
    maxWidth: "50%",
  },
  image: {
    width: "100%",
  },
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  preOrderIban: actionCreators.preOrderIban,
};

export default connect(mapStateToProps, mapDispatchToProps)(APIIntegration);
