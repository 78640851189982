import * as React from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import useMediaQuery from "@mui/material/useMediaQuery";
import Typography from "@mui/material/Typography";

import IbanDivider from "assets/images/iban-divider.jpg";

import InfoItem from "components/InfoItem";

import { IbanQrInvoiceResponseDto } from "api";

import { getPriceFormat } from "shared/functions";

import analytics from "services/analytics";
import { AnalyticsEvents } from "shared/constants/analytics";

import { grey000, grey200, grey800, grey900, mainStyles } from "styles";

interface Props {
  iban: IbanQrInvoiceResponseDto | null;
  onCopy: () => Promise<void>;
  onGoBack: () => void;
}

const Info: React.FC<Props> = (props) => {
  const { iban, onCopy, onGoBack } = props;
  const isXs = useMediaQuery("(max-width: 600px)");

  const onGoToLink = () => {
    analytics().sendEvent(AnalyticsEvents.iban_oplata_open_qr_url);
    iban?.ibanInvoiceUrl && window.open(iban?.ibanInvoiceUrl, "_blank");
  };
  return (
    <Box sx={styles.container}>
      <Box mb={4}>
        <Typography sx={mainStyles.h3} mb={1}>
          Перегляд посилання
        </Typography>
        <Typography sx={mainStyles.body2}>
          Для здійснення оплати надішліть клієнту посилання на оплату.
        </Typography>
      </Box>
      <Box sx={styles.infoContainer} mb={3}>
        <Box textAlign="center">
          <Typography sx={[mainStyles.body1, { color: grey900 }]}>
            Оплата рахунку по IBAN
          </Typography>
          <Box px={1.5} py={2} mt={2} sx={styles.amountContainer}>
            <Typography sx={[mainStyles.ui2, { color: grey800 }]} mb={0.5}>
              Сума до сплати:
            </Typography>
            <Typography sx={mainStyles.h4}>
              {getPriceFormat(iban?.amount || 0)} ₴
            </Typography>
          </Box>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mt={2}
          mb={2}
        >
          <img src={IbanDivider} />
          <Typography
            sx={[mainStyles.ui2, { color: grey800 }]}
            ml={1.5}
            mr={1.5}
          >
            Реквізити
          </Typography>
          <img src={IbanDivider} />
        </Box>
        <Box>
          <InfoItem
            id="organization_name"
            title="Юридична назва компанії або ФОП"
            value={iban?.organizationName || ""}
            copyEnabled
          />
          <InfoItem
            id="identificatio_code"
            title="ЄДРПОУ/РНОКПП"
            value={iban?.identificationCode || ""}
            copyEnabled
          />
          <InfoItem
            id="iban"
            title="IBAN"
            value={iban?.iban || ""}
            copyEnabled
            copyWithoutSpaces
          />
          <InfoItem
            id="payment_purpose"
            title="Призначення платежу"
            value={iban?.paymentPurpose || ""}
            copyEnabled
          />
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection={{ xs: "column", md: "row" }}
        width="100%"
      >
        <Button
          sx={[mainStyles.primaryButton, { marginRight: "16px" }]}
          onClick={() => onCopy()}
          fullWidth
        >
          <Typography sx={mainStyles.primaryButtonText}>
            Скопіювати посилання
          </Typography>
        </Button>
        <Button
          sx={[
            mainStyles.secondaryButton,
            { marginTop: { xs: "16px", md: "0px" } },
          ]}
          onClick={() => onGoToLink()}
          fullWidth
        >
          <Typography sx={mainStyles.secondaryButtonText}>
            Переглянути посилання
          </Typography>
        </Button>
      </Box>
       <Box mt={{ xs: 0, md: 2 }} width="100%">
        <Button sx={mainStyles.linkButton} onClick={() => onGoBack()} fullWidth>
          <Typography sx={mainStyles.linkButtonText}>
            {`Створити нове посилання${isXs ? "" : " на оплату"}`}
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};

const styles = {
  container: {
    padding: { xs: "24px", sm: "32px" },
    borderRadius: "20px",
    border: `8px #E1E8FA solid`,
    backgroundColor: "#E1E8FA",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  infoContainer: {
    padding: "24px 16px",
    width: "256px",
    borderRadius: "24px",
    backgroundColor: grey000,
    border: `8px ${grey900} solid`,
  },
  codeText: {
    color: grey800,
    textAlign: "left",
  },
  amountContainer: {
    backgroundColor: grey200,
    borderRadius: "8px",
  },
};

export default Info;
