import * as React from "react";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

import SolutionHeart from "assets/images/solution-heart.png";
import SolutionTime from "assets/images/solution-time.png";
import SolutionQrCode from "assets/images/solution-qrcode.png";
import { grey900, mainStyles } from "styles";

const solutions = [
  {
    image: SolutionHeart,
    bgColor: "#E6EFEA",
    title: "Для підприємців",
    text: "Це можливість перестати щоразу надсилати реквізити в месенджерах і турбуватись про можливі одруки.",
  },
  {
    image: SolutionTime,
    bgColor: "#E3E4EA",
    title: "Для клієнтів",
    text: "Це зручний спосіб здійснити оплату без зайвих кроків, користуючись своїми банківськими застосунками.",
  },
  {
    image: SolutionQrCode,
    bgColor: "#EFECE6",
    title: "Для бізнесу",
    text: "Автоматизація платіжних посилань за допомогою API-інтеграції сервісу з вашими CRM та іншими бізнес-платформами.",
  },
];

const Solution: React.FC = () => {
  return (
    <Container sx={styles.solutionsBlock}>
      <Box>
        <Typography sx={mainStyles.ui1}>
          заощаджуйте час своїх покупців
        </Typography>
        <Typography
          sx={[mainStyles.h2, { whiteSpace: "pre-line" }]}
          mt={2}
          mb={6}
        >
          <Typography sx={[mainStyles.h2, styles.highlightedText]}>
            IBAN oplata
          </Typography>{" "}
          знижує ризик помилок та {`\n`}пришвидшує процес оплати
        </Typography>
      </Box>
      <Box sx={styles.solutionsWrapper}>
        {solutions.map((solution, index) => (
          <Box
            key={index}
            sx={[styles.solution, { backgroundColor: solution.bgColor }]}
            p={5}
            m={1}
          >
            <img src={solution.image} />
            <Typography sx={mainStyles.h3} mb={1.5} mt={5}>
              {solution.title}
            </Typography>
            <Typography sx={[mainStyles.body1, { color: grey900 }]}>
              {solution.text}
            </Typography>
          </Box>
        ))}
      </Box>
    </Container>
  );
};

const styles = {
  solutionsBlock: {
    textAlign: "center",
    paddingTop: { xs: "56px", sm: "120px" },
    paddingBottom: { xs: "56px", sm: "120px" },
  },
  solutionsWrapper: {
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    justifyContent: { xs: "center", md: "space-between" },
    alignItems: { xs: "center", md: "stretch" },
  },
  solution: {
    width: { xs: "100%", sm: "70%", md: "100%" },
  },
  highlightedText: {
    display: "inline",
    color: "#1653F0",
  },
};

export default Solution;
