import React, { useState } from "react";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

import Form from "./Form";
import Info from "./Info";

import { actionCreators } from "store/IbanQr";
import {
  actionCreators as notificationActions,
  NotificationState,
} from "store/Notification";

import { FormValues, validationSchema } from "./validation";

import { ApplicationState } from "store";
import { CreateIbanQrInvoiceRequestDto, IbanQrInvoiceResponseDto } from "api";

import analytics from "services/analytics";
import { AnalyticsEvents } from "shared/constants/analytics";

import { blue100, blue600, grey800, grey900, mainStyles } from "styles";

export enum Steps {
  form,
  info,
}
interface Props {
  iban: IbanQrInvoiceResponseDto | null;
  createIbanQr: (
    body: CreateIbanQrInvoiceRequestDto,
    onSuccess?: () => void,
    onError?: () => void
  ) => Promise<void>;
  setNotification: (
    payload: Partial<Pick<NotificationState, "message" | "severity">>
  ) => void;
}

const CreateIban: React.FC<Props> = (props) => {
  const [step, setStep] = useState(Steps.form);
  const [isLoading, setIsLoading] = useState(false);

  const methods = useForm<FormValues>({
    mode: "onSubmit",
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = () => {
    analytics().sendEvent(AnalyticsEvents.iban_oplata_create_qr_url);

    methods.handleSubmit(async (values: FormValues) => {
      const { createIbanQr } = props;
      setIsLoading(true);

      await createIbanQr(
        { ...values, iban: values.iban.replace(/\s/g, "") },
        () => {
          setIsLoading(false);
          setStep(Steps.info);
        },
        () => {
          setIsLoading(false);
        }
      );
    })();
  };

  const onCopy = async () => {
    analytics().sendEvent(AnalyticsEvents.iban_oplata_copy_qr_url);

    const { iban, setNotification } = props;

    try {
      iban?.ibanInvoiceUrl &&
        (await navigator.clipboard.writeText(iban?.ibanInvoiceUrl));

      setNotification({
        message: "Посилання успішно скопійовано",
        severity: "success",
      });
    } catch (err) {
      setNotification({
        message: "Трапилась помилка при копіюванні. Спробуйте пізніше",
        severity: "error",
      });
    }
  };

  const onGoBack = () => {
    analytics().sendEvent(AnalyticsEvents.iban_oplata_create_another_qr_url);
    setStep(Steps.form);
  };

  const renderStep = () => {
    const { iban } = props;

    switch (step) {
      case Steps.form:
        return (
          <Form onSubmit={onSubmit} methods={methods} isLoading={isLoading} />
        );
      case Steps.info:
        return <Info iban={iban} onCopy={onCopy} onGoBack={onGoBack} />;
    }
  };

  return (
    <Box sx={styles.mainBlock}>
      <Container sx={styles.container}>
        <Box mb={{ xs: 2, sm: 5 }}>
          <Typography sx={[mainStyles.ui1, styles.uiText]}>
            отримуйте оплати миттєво
          </Typography>
          <Typography
            sx={[styles.titleText, { whiteSpace: "pre-line" }]}
            mt={2}
            mb={3}
          >
            <Typography sx={[styles.titleText, styles.highlightedText]}>
              IBAN oplata
            </Typography>{" "}
            безкоштовний {"\n"}сервіс для платежів
          </Typography>
          <Typography sx={[mainStyles.h5, { color: grey800 }]}>
            Більше жодних довгих повідомлень з реквізитами.
          </Typography>
        </Box>

        <Box sx={styles.formOuterContainer}>{renderStep()}</Box>
      </Container>
    </Box>
  );
};

const styles = {
  mainBlock: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    backgroundColor: "#F5F8FF",
    margin: { xs: "0px", sm: "32px 32px 0" },
    borderRadius: { xs: "0", sm: "24px" },
    backgroundImage: `
          linear-gradient(to left, ${blue100} 2px, transparent 2px),
          linear-gradient(to top, ${blue100} 2px, transparent 2px)
        `,
    backgroundSize: "220px 156px",
    backgroundPosition: "140px 40px",
    paddingTop: { xs: "56px", sm: "60px" },
    paddingBottom: { xs: "56px", sm: "60px" },
    overflow: "hidden",
    "&::before, &::after": {
      content: '""',
      position: "absolute",
      borderRadius: "50%",
      width: "1350px",
      height: "1350px",
      background:
        "radial-gradient(circle, rgba(0, 0, 255, 0.2) 0%, rgba(255, 255, 255, 0) 70%, transparent 100%)",
      zIndex: 0,
    },
    "&::before": {
      top: "-800px",
      right: "-700px",
    },
    "&::after": {
      bottom: "-1000px",
      left: "-800px",
    },
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1,
  },
  uiText: {
    color: blue600,
  },
  titleText: {
    fontFamily: "Inter",
    color: grey900,
    fontSize: { xs: "36px", sm: "60px" },
    lineHeight: { xs: "42px", sm: "68px" },
    letterSpacing: "-0.8px",
    fontWeight: "600",
  },
  formOuterContainer: {
    width: { xs: "auto", md: "600px" },
    maxWidth: "600px",
    padding: "12px",
    borderRadius: "20px",
    border: `2px ${blue100} solid`,
    backgroundColor: "#F5F8FF",
  },
  highlightedText: {
    display: "inline",
    color: blue600,
  },
};

const mapStateToProps = ({ ibanQr }: ApplicationState) => ({
  iban: ibanQr.iban,
});

const mapDispatchToProps = {
  createIbanQr: actionCreators.createIbanQr,
  setNotification: notificationActions.setNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateIban);
